// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news/detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-park-js": () => import("./../../../src/pages/park.js" /* webpackChunkName: "component---src-pages-park-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-ren-lian-shi-bie-js": () => import("./../../../src/pages/ren-lian-shi-bie.js" /* webpackChunkName: "component---src-pages-ren-lian-shi-bie-js" */),
  "component---src-pages-shou-ji-kai-men-js": () => import("./../../../src/pages/shou-ji-kai-men.js" /* webpackChunkName: "component---src-pages-shou-ji-kai-men-js" */)
}

